import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

export const generateWeights = createAsyncThunk(
  'search/generateWeights',
  async (query, { rejectWithValue }) => {
    try {
      const response = await api.generateWeights(query);
      return response.weights;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const searchCandidates = createAsyncThunk(
  'search/searchCandidates',
  async ({ query, weights, listingId, minGpa, logForFinetuning }, { rejectWithValue }) => {
    try {
      const response = await api.searchProjects(query, weights, listingId, logForFinetuning);
      
      console.log('Search API Response:', response);
      
      // Verify successful response
      if (!response.success) {
        return rejectWithValue(response.error || 'Search request failed');
      }

      // Ensure we have results array
      if (!Array.isArray(response.results)) {
        console.error('Results is not an array:', response.results);
        return rejectWithValue('Invalid results format from server');
      }

      return {
        results: response.results.map((result, index) => {
          if (!result) {
            console.warn(`Null or undefined result at index ${index}`);
            return null;
          }
          
          return {
            rank: index + 1,
            name: result.person_name || 'Unknown',
            score: Math.round((result.score || 0) * 100),
            match_reason: result.match_reason || '',
            education_level: result.education_level || '',
            education_details: result.education_details || '',
            undergrad_gpa: result.undergrad_gpa || 0.0,
            projects: Array.isArray(result.projects) 
              ? result.projects.map(project => ({
                  project_name: project.project_name || '',
                  description: project.description || '',
                  match_reason: project.match_reason || '',
                  score: Math.round((project.score || 0) * 100),
                  company: project.company || ''  
                }))
              : []
          };
        }).filter(Boolean), // Remove any null results
        key_experiences: response.key_experiences || []
      };
    } catch (error) {
      console.error('Search error:', error);
      return rejectWithValue(error.message || 'An error occurred during search');
    }
  }
);

export const processResume = createAsyncThunk(
  'search/processResume',
  async ({ file, githubUsername, namespace }, { rejectWithValue }) => {
    try {
      const response = await api.processResume(file, githubUsername, namespace);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    candidates: [],
    key_experiences: [],
    weightsLoading: false,
    searchLoading: false,
    uploadLoading: false,
    selectedIndex: 'all',
    error: null
  },
  reducers: {
    clearResults: (state) => {
      state.candidates = [];
      state.key_experiences = [];
      state.error = null;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateWeights.pending, (state) => {
        state.weightsLoading = true;
        state.error = null;
      })
      .addCase(generateWeights.fulfilled, (state, action) => {
        state.weightsLoading = false;
        state.key_experiences = action.payload;
      })
      .addCase(generateWeights.rejected, (state, action) => {
        state.weightsLoading = false;
        state.error = action.payload;
      })
      .addCase(searchCandidates.pending, (state) => {
        state.searchLoading = true;
        state.error = null;
      })
      .addCase(searchCandidates.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.candidates = action.payload.results;
        state.key_experiences = action.payload.key_experiences;
      })
      .addCase(searchCandidates.rejected, (state, action) => {
        state.searchLoading = false;
        state.error = action.payload;
      })
      .addCase(processResume.pending, (state) => {
        state.uploadLoading = true;
        state.error = null;
      })
      .addCase(processResume.fulfilled, (state) => {
        state.uploadLoading = false;
      })
      .addCase(processResume.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearResults, setSelectedIndex } = searchSlice.actions;
export default searchSlice.reducer;