// File: src/components/ResultsDisplay/ResultsDisplay.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  LinearProgress,
  CircularProgress,
  Drawer,
  useMediaQuery,
  useTheme,
  InputAdornment,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import DashboardLayout from '../Dashboard/DashboardLayout';
import WeightSliders from '../Settings/WeightSliders';
import { generateWeights, searchCandidates, clearResults } from '../../services/searchSlice';

import '@fontsource/plus-jakarta-sans';

// Utility for color gradient based on candidate score
const getGradientByScore = (score) => {
  if (score >= 75) {
    return 'linear-gradient(135deg, #3B6AC1 0%, #2B4C8C 100%)';
  } else if (score >= 35) {
    return 'linear-gradient(135deg, #2B4C8C 0%, #1A365D 100%)';
  }
  return 'linear-gradient(135deg, #1A365D 0%, #0F2442 100%)';
};

// =========== Layout Wrappers ===========

const PageContainer = styled(Box)(() => ({
  width: '100%',
  maxWidth: '1300px',
  margin: '0 auto',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
}));

const Title = styled(Typography)(() => ({
  fontSize: '32px',
  fontWeight: 700,
  color: '#1A365D',
  marginBottom: 4,
}));

const Subtitle = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#4A5568',
  marginBottom: 24,
}));

const MainContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const LeftColumn = styled(Paper)(() => ({
  flex: 1,
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  backgroundColor: '#ffffff',
}));

const RightColumn = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const TruncatingTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: 12,
    transition: 'box-shadow 0.2s ease',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.08)',
    },
    '&.Mui-focused': {
      boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    },
  },
}));

// =========== Candidate Card Styling ===========

const CandidateCard = styled(Paper)(({ theme, score }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #E1E7F0',
  borderRadius: '12px',
  backgroundColor: '#FFFFFF',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  // Subtle shadow for a more "professional" look:
  boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: '#F8FAFD',
    boxShadow: '0 4px 8px rgba(0,0,0,0.07)',
  },
}));

// ScoreChip from old code
const ScoreChip = styled(Box)(({ theme, score }) => ({
  width: '65px',
  height: '65px',
  [theme.breakpoints.down('sm')]: {
    width: '45px',
    height: '45px',
    fontSize: '14px',
  },
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: '18px',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  color: '#fff',
  background: getGradientByScore(score),
}));

// Education info area
const EducationInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  alignItems: 'center',
}));

const EducationChip = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(59, 106, 193, 0.1)',
  color: '#2B4C8C',
  padding: '4px 12px',
  borderRadius: '12px',
  fontSize: '13px',
  fontWeight: 500,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
  fontFamily: '"Plus Jakarta Sans", sans-serif',
}));

// Arrow in the bottom-right that points top-right
const ArrowBottomRight = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1.5),
  right: theme.spacing(1.5),
  cursor: 'pointer', // So user can open the drawer with this arrow
}));

const ArrowCircle = styled(Box)(() => ({
  width: 28,
  height: 28,
  borderRadius: '50%',
  backgroundColor: '#EAF0FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#2B4C8C',
}));

// Fix the arrow so it truly points top-right
const UpRightArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M7 17L17 7" />
    <path d="M7 7h10v10" />
  </svg>
);

// =========== Drawer (appearance changed for more professional look) ===========

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    maxWidth: '800px',
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '80px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#3B6AC1',
      borderRadius: '4px',
      '&:hover': {
        background: '#2B4C8C',
      },
    },
  },
}));

// Drawer Header
const DrawerHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '16px 24px',
  borderBottom: '1px solid #E5E7EB',
  backgroundColor: '#FFFFFF',
}));

// Add BackButton styling
const BackButton = styled(IconButton)(() => ({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: '#F3F4F6',
  padding: '8px',
  color: '#1E40AF',
  '&:hover': {
    backgroundColor: '#E5E7EB',
  },
}));

// Drawer Content
const DrawerContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

// For each project inside the drawer
const ProjectCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  '&:last-child': {
    marginBottom: 0,
  },
}));

// =========== Main Component ===========

function ResultsDisplay() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { candidates, weightsLoading, searchLoading, error, key_experiences } =
    useSelector((state) => state.search);

  const location = useLocation();
  const { listingId: urlListingId } = useParams();
  const listing = location.state?.listing;
  const listingId = urlListingId || listing?.id;
  const isAllSearch = urlListingId === 'all';

  // job description
  const [jobDescription, setJobDescription] = useState('');
  const [showError, setShowError] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  // weighting
  const [adjustedWeights, setAdjustedWeights] = useState([]);
  const [minGpa, setMinGpa] = useState(3.7);

  // processed results
  const [processedCandidates, setProcessedCandidates] = useState([]);

  // searching progress
  const [showSearchProgress, setShowSearchProgress] = useState(false);
  const [searchProgress, setSearchProgress] = useState(0);

  // drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  // log for fine-tuning
  const [logForFinetuning, setLogForFinetuning] = useState(false);

  // On mount
  useEffect(() => {
    if (listing?.job_description) {
      setJobDescription(listing.job_description);
      setWordCount(countWords(listing.job_description));
    }
    return () => {
      dispatch(clearResults());
    };
  }, [dispatch, listing]);

  useEffect(() => {
    setProcessedCandidates(candidates);
  }, [candidates]);

  useEffect(() => {
    if (!searchLoading) {
      setShowSearchProgress(false);
      setSearchProgress(0);
      return;
    }
    setShowSearchProgress(true);
    setSearchProgress(0);

    // Faux progress bar
    const duration = 30000;
    const target = 90;
    const interval = 100;
    const inc = target / (duration / interval);

    const timer = setInterval(() => {
      setSearchProgress((prev) => {
        if (prev >= target) {
          clearInterval(timer);
          return target;
        }
        return Math.min(prev + inc, target);
      });
    }, interval);

    return () => clearInterval(timer);
  }, [searchLoading]);

  // Counting words
  const countWords = (text) =>
    text.trim().split(/\s+/).filter((w) => w.length > 0).length;

  // Handle job desc changes
  const handleTextChange = (e) => {
    const text = e.target.value;
    setJobDescription(text);
    setWordCount(countWords(text));
    setShowError(false);
  };

  // Generate Key Experiences
  const handleGenerateWeights = async () => {
    if (wordCount < 20) {
      setShowError(true);
      return;
    }
    if (!jobDescription.trim()) {
      alert('Please enter a job description.');
      return;
    }
    await dispatch(generateWeights(jobDescription));
  };

  // Final search
  const handleSearch = async () => {
    if (!jobDescription.trim()) {
      alert('Please enter a job description.');
      return;
    }
    const weightsToUse = adjustedWeights.length > 0 ? adjustedWeights : key_experiences || [];
    const totalWeight = weightsToUse.reduce((sum, w) => sum + w[1], 0);
    if (totalWeight <= 0) {
      alert('Total weight must be > 0.');
      return;
    }
    const normalized = weightsToUse.map((w) => [w[0], w[1] / totalWeight]);

    setShowSearchProgress(true);
    setSearchProgress(0);

    dispatch(searchCandidates({
      query: jobDescription,
      weights: normalized,
      listingId: isAllSearch ? 'all' : listingId,
      minGpa,
      logForFinetuning
    }));
  };

  // Drawer
  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedCandidate(null);
  };

  const showWeightSliders = key_experiences && key_experiences.length > 0;

  return (
    <DashboardLayout>
      <Box sx={{ padding: { xs: 2, md: 4 } }}>
        <Box sx={{ maxWidth: '1300px', margin: '0 auto' }}>
          <Title sx={{ pl: 3 }}>
            {isAllSearch ? 'Talent Screen (All Applicants)' : 'Talent Screen'}
          </Title>
          <Subtitle sx={{ pl: 3 }}>
            Discover exceptional talent tailored to your needs—let us help you build your dream team!
          </Subtitle>

          <PageContainer>
            {/* 2-Column Layout */}
            <MainContent>
              {/* Left: Job Description */}
              <LeftColumn>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 16,
                    color: '#2B4C8C',
                    marginBottom: 1,
                  }}
                >
                  Job Description
                </Typography>
                <TruncatingTextField
                  multiline
                  minRows={8}
                  value={jobDescription}
                  onChange={handleTextChange}
                  placeholder="Enter or paste your job description..."
                  error={showError}
                  helperText={
                    showError
                      ? `Please enter at least 20 words. (current: ${wordCount})`
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleGenerateWeights}
                          disabled={wordCount < 20 || !jobDescription.trim()}
                          sx={{
                            color: wordCount < 20 || !jobDescription.trim() ? '#9CA3AF' : '#3B6AC1',
                            '&:hover': {
                              backgroundColor: wordCount < 20 || !jobDescription.trim()
                                ? 'transparent'
                                : 'rgba(59,106,193,0.1)',
                            },
                          }}
                        >
                          {weightsLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <TuneIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 1 }}
                />
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontSize: 12,
                    color: '#718096',
                  }}
                >
                  Word Count: {wordCount}
                </Typography>
              </LeftColumn>

              {/* Right: WeightSliders or placeholder */}
              <RightColumn>
                {showWeightSliders ? (
                  <WeightSliders
                    experiences={key_experiences}
                    onWeightsChange={setAdjustedWeights}
                    onSearch={handleSearch}
                    searchLoading={searchLoading}
                    onGpaChange={setMinGpa}
                  />
                ) : (
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      backgroundColor: '#F9FAFB',
                      padding: 3,
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 700, color: '#2B4C8C', mb: 1 }}
                    >
                      Ideal Candidate Criteria
                    </Typography>
                    <Typography sx={{ fontSize: 14, color: '#4A5568' }}>
                      Please enter a job description and click <strong>Search</strong> to populate candidate criteria.
                    </Typography>
                  </Paper>
                )}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  mt: 2,
                  mb: 2 
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={logForFinetuning}
                        onChange={(e) => setLogForFinetuning(e.target.checked)}
                        sx={{
                          color: '#3B6AC1',
                          '&.Mui-checked': {
                            color: '#3B6AC1',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '0.9rem', color: '#4A5568' }}>
                        Log search data for fine-tuning
                      </Typography>
                    }
                  />
                </Box>
              </RightColumn>
            </MainContent>

            {/* Searching Progress Bar */}
            {showSearchProgress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <Typography sx={{ fontSize: 14, color: '#3B6AC1', mb: 1 }}>
                  Searching candidates...
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={searchProgress}
                  sx={{
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: 'rgba(59,106,193,0.1)',
                    '& .MuiLinearProgress-bar': {
                      background: 'linear-gradient(135deg, #3B6AC1 0%, #2B4C8C 100%)',
                    },
                  }}
                />
              </Box>
            )}

            {/* Error */}
            {error && (
              <Typography color="error" sx={{ fontSize: 14, mt: 2 }}>
                {error}
              </Typography>
            )}

            {/* Candidate Results */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              {processedCandidates.map((candidate) => (
                <CandidateCard
                  key={candidate.name}
                  score={candidate.score}
                  onClick={() => handleCandidateClick(candidate)}
                >
                  {/* Top Row: rank, name, reason, and education */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      gap: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* Left side */}
                    <Box sx={{ flex: 1 }}>
                      {/* Rank + Name */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          marginBottom: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            width: isMobile ? 'auto' : '60px',
                            fontWeight: 600,
                            color: '#3B6AC1',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans", sans-serif',
                            fontSize: isMobile ? '16px' : '18px',
                          }}
                        >
                          #{candidate.rank}
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: '#2B4C8C',
                            textAlign: 'left',
                            fontFamily: '"Plus Jakarta Sans", sans-serif',
                            fontSize: isMobile ? '14px' : '16px',
                          }}
                        >
                          {candidate.name}
                        </Typography>
                      </Box>

                      {/* Match reason */}
                      <Typography
                        sx={{
                          color: '#4A5568',
                          textAlign: 'left',
                          fontFamily: '"Plus Jakarta Sans", sans-serif',
                          fontSize: isMobile ? '14px' : '15px',
                          marginBottom: 1,
                        }}
                      >
                        {candidate.match_reason}
                      </Typography>

                      {/* Education Info */}
                      <EducationInfo>
                        {candidate.education_details.split(';').map((edu, index) => {
                          const [degree, school] = edu.split(':');
                          return (
                            <EducationChip key={index}>
                              {/* School Icon */}
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#2B4C8C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M2 7l10-5 10 5-10 5z" />
                                <path d="M2 17l10 5 10-5" />
                                <path d="M2 12l10 5 10-5" />
                              </svg>
                              <span>{`${degree} @ ${school}`}</span>
                            </EducationChip>
                          );
                        })}
                        {candidate.undergrad_gpa > 0 && (
                          <EducationChip>
                            {/* GPA icon */}
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#2B4C8C"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M22 12h-4l-3 9-3-9H4" />
                            </svg>
                            <span>GPA: {candidate.undergrad_gpa.toFixed(2)}</span>
                          </EducationChip>
                        )}
                      </EducationInfo>
                    </Box>

                    {/* Right side: ScoreChip 
                        (We remove the old right arrow icon button 
                         so only bottom arrow is used) */}
                    <ScoreChip score={candidate.score} sx={{ alignSelf: 'center' }}>
                      {candidate.score}%
                    </ScoreChip>
                  </Box>

                  {/* Bottom-right arrow that opens the drawer */}
                  <ArrowBottomRight onClick={() => handleCandidateClick(candidate)}>
                    <ArrowCircle>
                      <UpRightArrowIcon />
                    </ArrowCircle>
                  </ArrowBottomRight>
                </CandidateCard>
              ))}
            </motion.div>
          </PageContainer>
        </Box>
      </Box>

      {/* Drawer with updated styling */}
      <StyledDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        {selectedCandidate && (
          <>
            <DrawerHeader>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <BackButton onClick={handleDrawerClose}>
                  <KeyboardArrowLeftIcon fontSize="small" />
                </BackButton>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      fontWeight: 600,
                      fontSize: '16px',
                      color: '#1E40AF',
                      lineHeight: 1.5,
                    }}
                  >
                    {selectedCandidate.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      fontSize: '14px',
                      color: '#6B7280',
                      lineHeight: 1.4,
                    }}
                  >
                    Applicant Summary
                  </Typography>
                </Box>
              </Box>
            </DrawerHeader>

            <DrawerContent>
              {/* Match reason */}
              <Typography
                sx={{
                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                  color: '#2B4C8C',
                  fontSize: {
                    xs: '14px',
                    sm: '16px',
                  },
                  fontWeight: 500,
                  mb: 3,
                }}
              >
                {selectedCandidate.match_reason}
              </Typography>

              {/* Education */}
              <EducationInfo sx={{ marginBottom: 3 }}>
                {selectedCandidate.education_details.split(';').map((edu, index) => {
                  const [degree, school] = edu.split(':');
                  return (
                    <EducationChip key={index}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#2B4C8C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M2 7l10-5 10 5-10 5z" />
                        <path d="M2 17l10 5 10-5" />
                        <path d="M2 12l10 5 10-5" />
                      </svg>
                      <span>{`${degree} @ ${school}`}</span>
                    </EducationChip>
                  );
                })}
                {selectedCandidate.undergrad_gpa > 0 && (
                  <EducationChip>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#2B4C8C"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M22 12h-4l-3 9-3-9H4" />
                    </svg>
                    <span>GPA: {selectedCandidate.undergrad_gpa.toFixed(2)}</span>
                  </EducationChip>
                )}
              </EducationInfo>

              {/* Projects */}
              {selectedCandidate.projects.map((project, index) => (
                <ProjectCard key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      marginBottom: 2,
                    }}
                  >
                    <ScoreChip
                      score={project.score}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          width: '40px',
                          height: '40px',
                          fontSize: '14px',
                        },
                      }}
                    >
                      {project.score}%
                    </ScoreChip>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          fontFamily: '"Plus Jakarta Sans", sans-serif',
                          fontWeight: 600,
                          color: '#1A365D',
                          fontSize: {
                            xs: '16px',
                            sm: '18px',
                          },
                          marginBottom: project.company ? 0.5 : 0,
                        }}
                      >
                        {project.project_name || `Project ${index + 1}`}
                      </Typography>

                      {project.company && (
                        <Typography
                          sx={{
                            fontFamily: '"Plus Jakarta Sans", sans-serif',
                            color: '#4A5568',
                            fontSize: {
                              xs: '13px',
                              sm: '14px',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M3 3h18v18H3zM3 9h18M9 21V9" />
                            <path d="M14 21V9M15 6v3M9 6v3" />
                          </svg>
                          {project.company}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      fontWeight: 500,
                      color: '#2B4C8C',
                      marginBottom: 2,
                      fontSize: {
                        xs: '14px',
                        sm: '15px',
                      },
                    }}
                  >
                    {project.match_reason}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                      color: '#4A5568',
                      fontSize: {
                        xs: '13px',
                        sm: '14px',
                      },
                      lineHeight: 1.6,
                    }}
                  >
                    {project.description}
                  </Typography>
                </ProjectCard>
              ))}
            </DrawerContent>
          </>
        )}
      </StyledDrawer>
    </DashboardLayout>
  );
}

export default ResultsDisplay;
