// File: src/components/Dashboard/Dashboard.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Grid,
  IconButton,
  Alert,
  AppBar,
  Toolbar,
  Slide,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// Icons - consolidate all MUI icons
import {
  Add as AddIcon,
  Search as SearchIcon,
  Upload as UploadIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  BarChart as BarChartIcon,
  CloudUpload as CloudUploadIcon,
  Dashboard as DashboardIcon,
  History as HistoryIcon,
  Logout as LogoutIcon
} from '@mui/icons-material';

// Stripe imports
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Local imports
import {
  fetchListings,
  initiateListingCreation,
  completeListingCreation,
  updateListing,
  deleteListing,
  clearPaymentIntent,
  validatePromoCode,
  clearPromoCode,
} from '../../services/listingSlice';
import { processResume } from '../../services/api';
import { logout } from '../../services/authSlice';
import DataInsights from './DataInsights';
import { CreditManager } from './CreditManager';
import { stripePromise } from './stripe';

// ----------------------------------------------
// Styled Components
// ----------------------------------------------
const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  background: '#fff',
}));

const Sidebar = styled(Box)(({ theme }) => ({
  width: '280px',
  backgroundColor: '#0747A6',
  color: 'white',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
  cursor: 'pointer',
  '& span': {
    fontSize: '24px',
    fontWeight: 600,
    color: 'white',
  },
  '&:hover': {
    opacity: 0.8,
  },
}));

const NavItem = styled(Button)(({ theme, active }) => ({
  color: 'white',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.5, 2),
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '16px',
  backgroundColor: active ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(3),
  backgroundColor: '#F4F5F7',
  overflowY: 'auto',
}));

const ListingCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  marginBottom: theme.spacing(2),
  backgroundColor: 'white',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
}));

// ----------------------------------------------
// Utility Components
// ----------------------------------------------
const TruncatedDescription = ({ text, maxLines = 3 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Typography
        sx={{
          mb: 2,
          color: '#666',
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'unset' : maxLines,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </Typography>
      {text?.length > 250 && (
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{ mb: 2, textTransform: 'none', fontSize: '14px' }}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </>
  );
};

// ----------------------------------------------
// Payment Form for Creating Listing
// ----------------------------------------------
const PaymentForm = ({ onSuccess, onError, listingData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [promoCode, setPromoCode] = useState('');

  const {
    promoCodeStatus,
    promoCodeDiscount,
    error,
    amount
  } = useSelector((state) => state.listings);

  const baseAmount = 5000; // $50.00 in cents
  const currentAmount = amount || baseAmount;

  const handleValidatePromoCode = async () => {
    if (!promoCode.trim()) return;
    try {
      await dispatch(validatePromoCode(promoCode)).unwrap();
      await dispatch(
        initiateListingCreation({
          listingData: {
            title: listingData.title,
            job_description: listingData.job_description,
            namespace: listingData.namespace || 'all',
          },
          promoCode: promoCode,
        })
      ).unwrap();
    } catch (err) {
      console.error('Promo code validation error:', err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // If amount is 0, skip payment confirmation
    if (currentAmount === 0) {
      setProcessing(true);
      try {
        // Complete creation without payment
        await dispatch(
          completeListingCreation({
            paymentIntentId: 'free',  // Special case for free listings
            listingData: {
              title: listingData.title,
              job_description: listingData.job_description,
              namespace: listingData.namespace || 'all',
            },
          })
        ).unwrap();

        onSuccess();
      } catch (err) {
        console.error('Error creating free listing:', err);
        onError(err.message || 'An error occurred while creating the listing');
      } finally {
        setProcessing(false);
      }
      return;
    }

    if (!stripe || !elements) return;

    setProcessing(true);
    try {
      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (paymentError) {
        throw new Error(paymentError.message);
      }

      // Complete creation
      await dispatch(
        completeListingCreation({
          paymentIntentId: paymentIntent.id,
          listingData: {
            title: listingData.title,
            job_description: listingData.job_description,
            namespace: listingData.namespace || 'all',
          },
        })
      ).unwrap();

      onSuccess();
    } catch (err) {
      console.error('Payment Error:', err);
      onError(err.message || 'An error occurred during payment');
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearPromoCode());
    };
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          error={promoCodeStatus === 'invalid'}
          helperText={promoCodeStatus === 'invalid' ? error : ''}
          fullWidth
          sx={{ mb: 1 }}
        />
        <Button
          onClick={handleValidatePromoCode}
          disabled={!promoCode || processing || promoCodeStatus === 'validating'}
          variant="outlined"
          fullWidth
        >
          {promoCodeStatus === 'validating' ? 'Validating...' : 'Apply Promo Code'}
        </Button>

        <Box sx={{ mt: 2 }}>
          {promoCodeStatus === 'valid' && promoCodeDiscount && (
            <Alert severity="success" sx={{ mb: 1 }}>
              Promo code applied! You’ll receive a{' '}
              {promoCodeDiscount.type === 'percentage'
                ? `${promoCodeDiscount.value}% discount`
                : `$${promoCodeDiscount.value / 100} discount`}
            </Alert>
          )}

          <Typography variant="body1" sx={{ textAlign: 'right' }}>
            Original price: ${(baseAmount / 100).toFixed(2)}
          </Typography>

          {promoCodeStatus === 'valid' && promoCodeDiscount && (
            <Typography variant="body1" color="success.main" sx={{ textAlign: 'right' }}>
              Discount: -${((baseAmount - currentAmount) / 100).toFixed(2)}
            </Typography>
          )}

          <Typography variant="h6" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            Total: ${(currentAmount / 100).toFixed(2)}
          </Typography>
        </Box>
      </Box>

      {/* Only show payment element if amount is not 0 */}
      {currentAmount > 0 ? (
        <PaymentElement />
      ) : (
        <Alert severity="success" sx={{ mb: 2 }}>
          Your promo code makes this listing free! Click submit to create your listing.
        </Alert>
      )}

      <Button
        type="submit"
        disabled={currentAmount > 0 ? !stripe || processing : processing}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
      >
        {processing ? 'Processing...' : currentAmount === 0 ? 'Create Free Listing' : 'Pay and Create Listing'}
      </Button>
    </form>
  );
};

// ----------------------------------------------
// Dashboard Component
// ----------------------------------------------
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { companyName } = useSelector((state) => state.auth);
  const {
    list: listings,
    loading,
    error,
    paymentIntent,
    pendingListingData,
  } = useSelector((state) => state.listings);

  // Create/Edit Dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingListing, setEditingListing] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    job_description: '',
    namespace: 'all',
  });

  // Delete Confirmation
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [listingToDelete, setListingToDelete] = useState(null);

  // Insights
  const [insightsDialogOpen, setInsightsDialogOpen] = useState(false);
  const [selectedListingForInsights, setSelectedListingForInsights] = useState(null);
  const [scoreDistribution, setScoreDistribution] = useState(null);

  // Resume Upload Popup
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [githubUsername, setGithubUsername] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const [selectedListingForUpload, setSelectedListingForUpload] = useState(null);

  useEffect(() => {
    dispatch(fetchListings());
  }, [dispatch]);

  // ------------------ Listing CRUD -------------------
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditingListing(null);
    setFormData({ title: '', job_description: '', namespace: 'all' });
    dispatch(clearPaymentIntent());
  };

  const handleEditListing = (listing) => {
    setEditingListing(listing);
    setFormData({
      title: listing.title,
      job_description: listing.job_description,
      namespace: listing.namespace,
    });
    setDialogOpen(true);
  };

  const handleDeleteClick = (listing) => {
    setListingToDelete(listing);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (listingToDelete) {
      await dispatch(deleteListing(listingToDelete.id));
      setDeleteDialogOpen(false);
      setListingToDelete(null);
    }
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      return 'Job title is required';
    }
    if (!formData.job_description.trim()) {
      return 'Job description is required';
    }
    return null;
  };

  const handleSubmitListing = async () => {
    const validationError = validateForm();
    if (validationError) {
      console.error(validationError);
      return;
    }

    try {
      if (editingListing) {
        await dispatch(
          updateListing({
            listingId: editingListing.id,
            listingData: formData,
          })
        ).unwrap();
        dispatch(fetchListings());
        handleCloseDialog();
      } else {
        // Initialize payment intent
        const paymentData = {
          listingData: {
            title: formData.title,
            job_description: formData.job_description,
            namespace: formData.namespace || 'all',
          },
        };
        await dispatch(initiateListingCreation(paymentData)).unwrap();
      }
    } catch (err) {
      console.error('Failed to save listing:', err);
    }
  };

  // ------------------ Insights -------------------
  const handleOpenInsights = (listing) => {
    setSelectedListingForInsights(listing);
    setInsightsDialogOpen(true);
  };

  // ------------------ Resume Upload Popup -------------------
  const handleOpenUploadDialog = (listing) => {
    setSelectedListingForUpload(listing);
    setUploadDialogOpen(true);
    resetUploadForm();
  };

  const resetUploadForm = () => {
    setUploadFile(null);
    setGithubUsername('');
    setUploadError(null);
    setUploadSuccess(false);
    setUploadLoading(false);
    setUploadResults(null);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialogOpen(false);
    resetUploadForm();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === 'application/pdf' || selectedFile.type === 'application/zip')
    ) {
      setUploadFile(selectedFile);
      // Reset GitHub username if ZIP
      if (selectedFile.type === 'application/zip') {
        setGithubUsername('');
      }
      setUploadError(null);
    } else {
      setUploadError('Please upload a PDF or ZIP file');
      setUploadFile(null);
    }
  };

  const handleSubmitUpload = async () => {
    setUploadError(null);
    setUploadSuccess(false);
    setUploadResults(null);

    if (!uploadFile) {
      setUploadError('Please select a file');
      return;
    }

    // Validate GitHub username only for PDF
    if (uploadFile.type === 'application/pdf' && githubUsername.trim() !== '') {
      if (!githubUsername.match(/^[a-zA-Z0-9-]+$/)) {
        setUploadError('Invalid GitHub username format');
        return;
      }
    }

    setUploadLoading(true);
    try {
      const listingId = selectedListingForUpload?.id;
      const result = await processResume(uploadFile, githubUsername, listingId);

      if (uploadFile.type === 'application/zip') {
        setUploadResults(result);
        setUploadSuccess(true);
      } else {
        setUploadSuccess(true);
      }

      setUploadFile(null);
      setGithubUsername('');
    } catch (err) {
      console.error('Resume upload error:', err);
      setUploadError(err.message || 'Failed to upload file');
    } finally {
      setUploadLoading(false);
    }
  };

  // --------------------------------------------------
  // RENDER
  // --------------------------------------------------
  return (
    <StyledContainer>
      {/* Sidebar */}
      <Sidebar>
        <Logo onClick={() => navigate('/')}>
          <span>intratalent ai</span>
        </Logo>
        <NavItem active>
          <DashboardIcon sx={{ mr: 2 }} />
          Dashboard
        </NavItem>
        <NavItem onClick={() => navigate('/search/all')}>
          <SearchIcon sx={{ mr: 2 }} />
          Search All Candidates
        </NavItem>
        <NavItem onClick={() => setDialogOpen(true)}>
          <AddIcon sx={{ mr: 2 }} />
          Create Listings
        </NavItem>
        <NavItem>
          <HistoryIcon sx={{ mr: 2 }} />
          History - Coming Soon!
        </NavItem>
        <Box sx={{ flexGrow: 1 }} />
        <NavItem onClick={() => dispatch(logout())}>
          <LogoutIcon sx={{ mr: 2 }} />
          Log out
        </NavItem>
      </Sidebar>

      {/* Main Content */}
      <MainContent>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3 
        }}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            Welcome, {companyName}
          </Typography>
          <CreditManager />
        </Box>
        
        <Typography variant="h5" sx={{ mb: 3 }}>
          Job Listings
        </Typography>

        <Grid container spacing={3}>
          {listings.map((listing) => (
            <Grid item xs={12} md={6} key={listing?.id}>
              <ListingCard>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {listing?.title}
                </Typography>
                <TruncatedDescription text={listing?.job_description} />

                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    startIcon={<SearchIcon />}
                    variant="outlined"
                    onClick={() => navigate(`/search/${listing.id}`, { state: { listing } })}
                  >
                    Search
                  </Button>
                  <Button
                    startIcon={<UploadIcon />}
                    variant="outlined"
                    onClick={() => handleOpenUploadDialog(listing)}
                  >
                    Upload
                  </Button>
                  <Button
                    startIcon={<BarChartIcon />}
                    variant="outlined"
                    onClick={() => handleOpenInsights(listing)}
                  >
                    Insights
                  </Button>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton
                    onClick={() => handleEditListing(listing)}
                    sx={{
                      color: '#3B6AC1',
                      '&:hover': {
                        backgroundColor: 'rgba(59, 106, 193, 0.1)',
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteClick(listing)}
                    sx={{
                      color: '#DC2626',
                      '&:hover': {
                        backgroundColor: 'rgba(220, 38, 38, 0.1)',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListingCard>
            </Grid>
          ))}
        </Grid>
      </MainContent>

      {/* Create/Edit Listing Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          {editingListing ? 'Edit Job Listing' : 'Create New Job Listing'}
          <IconButton
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Job Title"
            fullWidth
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Job Description"
            fullWidth
            multiline
            rows={4}
            value={formData.job_description}
            onChange={(e) => setFormData({ ...formData, job_description: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmitListing}
            variant="contained"
            sx={{ backgroundColor: '#3B6AC1', '&:hover': { backgroundColor: '#2B4C8C' } }}
          >
            {editingListing ? 'Save Changes' : 'Continue to Payment'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment Dialog (appears after 'Continue to Payment' if no editingListing) */}
      {paymentIntent && pendingListingData && (
        <Dialog
          open={true}
          onClose={() => dispatch(clearPaymentIntent())}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Complete Payment</DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Payment required: $50.00
            </Typography>
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntent,
                appearance: { theme: 'stripe' },
              }}
            >
              <PaymentForm
                onSuccess={() => {
                  dispatch(clearPaymentIntent());
                  handleCloseDialog();
                  dispatch(fetchListings());
                }}
                onError={(error) => {
                  console.error(error);
                }}
                listingData={pendingListingData}
              />
            </Elements>
          </DialogContent>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this job listing? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Insights Dialog */}
      <Dialog
        open={insightsDialogOpen}
        onClose={() => setInsightsDialogOpen(false)}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#3B6AC1' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setInsightsDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Insights for {selectedListingForInsights?.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: '100%', overflow: 'auto' }}>
          {selectedListingForInsights && (
            <DataInsights
              listing={selectedListingForInsights}
              scoreDistribution={scoreDistribution}
            />
          )}
        </Box>
      </Dialog>

      {/* Upload Resume Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={handleCloseUploadDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Upload Resumes
          <IconButton
            onClick={handleCloseUploadDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {selectedListingForUpload
              ? `Attach resumes for ${selectedListingForUpload.title}.`
              : 'Attach resumes.'}
          </Typography>

          {/* GITHUB Username (only relevant for single PDFs) */}
          {uploadFile?.type === 'application/pdf' && (
            <TextField
              fullWidth
              label="GitHub Username (Optional)"
              variant="outlined"
              value={githubUsername}
              onChange={(e) => setGithubUsername(e.target.value)}
              sx={{ mb: 3 }}
            />
          )}

          {/* File Input */}
          <Box sx={{ mb: 2 }}>
            <input
              id="resume-upload"
              type="file"
              accept=".pdf,.zip"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="resume-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                sx={{ textTransform: 'none', mb: 1 }}
              >
                {uploadFile ? uploadFile.name : 'Choose PDF or ZIP'}
              </Button>
            </label>
          </Box>

          {/* Upload Error/Success */}
          {uploadError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {uploadError}
            </Alert>
          )}
          {uploadSuccess && !uploadResults && (
            <Alert severity="success" sx={{ mb: 2 }}>
              Resume uploaded successfully!
            </Alert>
          )}
          {/* If multiple resumes (ZIP) have results */}
          {uploadResults && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Alert
                severity={uploadResults.failed_uploads > 0 ? 'warning' : 'success'}
                sx={{ mb: 2 }}
              >
                {`Processed ${uploadResults.total_files} files: ${uploadResults.successful_uploads} successful, ${uploadResults.failed_uploads} failed`}
              </Alert>
              {uploadResults.failed_uploads > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="subtitle2" color="error" sx={{ mb: 1 }}>
                    Failed Uploads:
                  </Typography>
                  {uploadResults.errors.map((error, index) => (
                    <Typography key={index} variant="body2" color="error">
                      {`${error.file_name}: ${error.error}`}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploadDialog}>Cancel</Button>
          <Button
            onClick={handleSubmitUpload}
            variant="contained"
            disabled={uploadLoading}
            startIcon={uploadLoading ? <CircularProgress size={18} color="inherit" /> : null}
            sx={{ textTransform: 'none' }}
          >
            {uploadLoading ? 'Uploading...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default Dashboard;