import axios from 'axios';
import {store} from './store'; 
import { logout } from './authSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
      'Content-Type': 'application/json',
  },
});

// Add interceptor to add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Dispatch logout action to clear auth state
      store.dispatch(logout());
      console.log('error response status 401')
    }
    return Promise.reject(error);
  }
);

// Auth endpoints
export const login = async (credentials) => {
  const response = await api.post('/auth/login', credentials);
  return response.data;
};

export const signup = async (credentials) => {
  const response = await api.post('/auth/signup', credentials);
  return response.data;
};

export const verifyEmail = async (token) => {
  const response = await api.get(`/auth/verify-email/${token}`);
  return response.data;
};

export const verifyToken = async () => {
  const response = await api.get('/auth/verify');
  return response.data;
};

// Listings endpoints
export const getListings = async () => {
  const response = await api.get('/listings/list');
  return response.data;
};

export const handleListingCreation = async (paymentData) => {
  try {
    console.log('Creating payment intent with data:', paymentData);
    const response = await api.post('/payments/create-payment-intent', paymentData);
    
    // If amount is 0, we don't need a client secret
    if (response.data.amount === 0) {
      return {
        clientSecret: null,
        amount: 0,
        listingData: paymentData.listing_data,
        isFree: true
      };
    }
    
    return {
      clientSecret: response.data.clientSecret,
      amount: response.data.amount,
      listingData: paymentData.listing_data,
      isFree: false
    };
  } catch (error) {
    console.error('Payment Intent Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to initiate payment';
  }
};

export const updateListing = async (listingId, listingData) => {
  const response = await api.put(`/listings/update/${listingId}`, listingData);
  return response.data;
};

export const deleteListing = async (listingId) => {
  const response = await api.delete(`/listings/delete/${listingId}`);
  return response.data;
};

export const getListing = async (listingId) => {
  const response = await api.get(`/listings/get/${listingId}`);
  return response.data;
};

// Create payment intent
export const createPaymentIntent = async () => {
  const response = await api.post('/payments/create-payment-intent');
  return response.data;
};

export const validatePromoCode = async (promoCode) => {
  const response = await api.post('/payments/validate-promo-code', { promo_code: promoCode });
  return response.data;
};

// Credit management endpoints
export const getCreditBalance = async () => {
  const response = await api.get('/payments/get-credit-balance');
  return response.data;
};

export const getCreditsPricing = async () => {
  const response = await api.get('/payments/get-credits-pricing');
  return response.data;
};

export const createCreditPayment = async (numCredits) => {
  try {
    const response = await api.post('/payments/create-credits-payment', {
      num_credits: numCredits
    });
    return {
      clientSecret: response.data.clientSecret,
      amount: response.data.amount,
      num_credits: response.data.num_credits
    };
  } catch (error) {
    console.error('Credit Payment Intent Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to initiate credit purchase';
  }
};

export const confirmCreditPayment = async (paymentIntentId) => {
  const response = await api.post('/payments/credits-payment-success', {
    payment_intent_id: paymentIntentId
  });
  return response.data;
};

// Confirm payment and create listing
export const confirmPaymentAndCreateListing = async (paymentIntentId, listingData) => {
  // If paymentIntentId is 'free', use the free listing endpoint
  if (paymentIntentId === 'free') {
    return createFreeListing(listingData);
  }
  
  const response = await api.post('/payments/payment-success', {
    payment_intent_id: paymentIntentId,
    listing_data: listingData
  });
  return response.data;
};

// Resume processing endpoints
export const generateWeights = async (query) => {
  try {
    const response = await api.post('/resume/generate_weights', { query });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'An error occurred while generating weights';
  }
};

export const searchProjects = async (query, weights = null, listingId = null, logForFinetuning = false) => {
  try {
    const requestBody = {
      query,
      ...(weights && { weights }),
      ...(logForFinetuning && { log_for_finetuning: logForFinetuning })
    };
    
    // Use demo route if no listingId is provided
    const endpoint = listingId ? '/resume/search' : '/resume/search_demo';
    
    if (listingId) {
      requestBody.listing_id = listingId;
    }
    
    const response = await api.post(endpoint, requestBody);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'An error occurred while searching';
  }
};

export const processResume = async (file, githubUsername, listingId) => {
  try {
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('github_username', githubUsername);
    formData.append('listing_id', listingId);

    console.log('Sending request with:', {
      file: file.name,
      githubUsername,
      listingId
    });
    
    const response = await api.post('/resume/process_resume', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || error.message || 'An error occurred while processing the resume');
  }
};

// Add this new function for free listings
export const createFreeListing = async (listingData) => {
  try {
    const response = await api.post('/listings/create_free', listingData);
    return response.data;
  } catch (error) {
    console.error('Free Listing Creation Error:', error.response?.data);
    throw error.response?.data?.error || 'Failed to create free listing';
  }
};

export default api;