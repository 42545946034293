// File: src/components/Dashboard/DataInsights.js

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Professional blue color palette
const COLORS = [
  '#2B4C8C', 
  '#4169B2',
  '#6B8CCE',
  '#89A7E0',
  '#A8C0ED',
  '#DBE6F6'
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  height: '100%',
  backgroundColor: '#FFFFFF',
  position: 'relative',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const RoundedBar = ({ x, y, width, height, fill }) => {
  const radius = 4;
  return (
    <rect x={x} y={y} width={width} height={height} rx={radius} fill={fill} />
  );
};

const DataInsights = ({ listing }) => {
  if (!listing) {
    return null;
  }

  // Check for any data
  const hasAnyData =
    listing.total_candidates > 0 ||
    Object.values(listing.score_distribution || {}).some((val) => val > 0) ||
    Object.values(listing.sector_distribution || {}).some((val) => val > 0) ||
    Object.values(listing.school_distribution || {}).some((val) => val > 0) ||
    Object.values(listing.gpa_distribution || {}).some((val) => val > 0) ||
    Object.values(listing.degree_distribution || {}).some((val) => val > 0);

  if (!hasAnyData) {
    return (
      <Box
        sx={{
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px',
        }}
      >
        <Alert
          severity="info"
          sx={{
            width: '100%',
            maxWidth: '500px',
            '& .MuiAlert-message': {
              width: '100%',
              textAlign: 'center',
              fontSize: '1.1rem',
            },
          }}
        >
          You must perform at least one search to generate insights.
        </Alert>
      </Box>
    );
  }

  // Utility to convert object distributions into chart data
  const toBarData = (obj) =>
    obj
      ? Object.entries(obj).map(([key, value]) => ({
          name: key,
          value,
        }))
      : [];

  // 1) Score Distribution
  const scoreData = toBarData(listing.score_distribution);
  const hasScoreData = scoreData.some((item) => item.value > 0);

  // 2) Sector Distribution (Pie)
  const sectorData = toBarData(listing.sector_distribution).sort((a, b) => b.value - a.value);
  const hasSectorData = sectorData.some((item) => item.value > 0);

  // 3) School Distribution (Top 5)
  let schoolData = toBarData(listing.school_distribution).sort((a, b) => b.value - a.value);
  schoolData = schoolData.slice(0, 5);
  const hasSchoolData = schoolData.some((item) => item.value > 0);

  // 4) GPA Distribution
  const gpaData = toBarData(listing.gpa_distribution);
  const hasGpaData = gpaData.some((item) => item.value > 0);

  // 5) Degree Distribution (Pie)
  let degreeData = toBarData(listing.degree_distribution).filter((d) => d.value > 0);
  degreeData = degreeData.map((d) => ({
    name: d.name.replace('_', ' '),
    value: d.value,
  }));
  const hasDegreeData = degreeData.length > 0;

  const renderScoreDistribution = () => (
    <StyledPaper>
      <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
        Candidate Score Distribution
      </Typography>
      <Box sx={{ height: 300, position: 'relative' }}>
        {!hasScoreData ? (
          <NoDataOverlay />
        ) : (
          <ResponsiveContainer>
            <BarChart data={scoreData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" shape={<RoundedBar />}>
                {scoreData.map((entry, index) => (
                  <Cell
                    key={`score-cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StyledPaper>
  );

  const renderSectorChart = () => (
    <StyledPaper>
      <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
        Project Sectors
      </Typography>
      <Box sx={{ height: 300, position: 'relative' }}>
        {!hasSectorData ? (
          <NoDataOverlay />
        ) : (
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={sectorData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {sectorData.map((entry, index) => (
                  <Cell
                    key={`sector-cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StyledPaper>
  );

  const renderSchoolsChart = () => (
    <StyledPaper>
      <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
        Top Schools
      </Typography>
      <Box sx={{ height: 300, position: 'relative' }}>
        {!hasSchoolData ? (
          <NoDataOverlay />
        ) : (
          <ResponsiveContainer>
            <BarChart data={schoolData} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" width={150} />
              <Tooltip />
              <Bar dataKey="value" fill={COLORS[0]} shape={<RoundedBar />} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StyledPaper>
  );

  const renderGpaChart = () => (
    <StyledPaper>
      <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
        GPA Distribution
      </Typography>
      <Box sx={{ height: 300, position: 'relative' }}>
        {!hasGpaData ? (
          <NoDataOverlay />
        ) : (
          <ResponsiveContainer>
            <BarChart data={gpaData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" shape={<RoundedBar />}>
                {gpaData.map((entry, index) => (
                  <Cell
                    key={`gpa-cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StyledPaper>
  );

  const renderDegreeChart = () => (
    <StyledPaper>
      <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
        Degree Distribution
      </Typography>
      <Box sx={{ height: 300, position: 'relative' }}>
        {!hasDegreeData ? (
          <NoDataOverlay />
        ) : (
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={degreeData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
              >
                {degreeData.map((entry, index) => (
                  <Cell
                    key={`degree-cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        )}
      </Box>
    </StyledPaper>
  );

  const renderTotalCandidates = () => (
    <StyledPaper>
      <Typography
        variant="h6"
        sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}
      >
        Total Candidates
      </Typography>
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h2" sx={{ color: COLORS[0], fontWeight: 600 }}>
          {listing?.total_candidates || 0}
        </Typography>
      </Box>
    </StyledPaper>
  );

  // Overlay for no-data
  const NoDataOverlay = () => (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
    >
      <Alert severity="info">No data available</Alert>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {renderScoreDistribution()}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderSectorChart()}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderTotalCandidates()}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderSchoolsChart()}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderGpaChart()}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderDegreeChart()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataInsights;




// import React from 'react';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
//   BarChart,
//   Bar,
//   PieChart,
//   Pie,
//   Cell,
// } from 'recharts';

// import {
//   Box,
//   Typography,
//   Paper,
//   Grid,
//   useTheme,
//   Alert
// } from '@mui/material';

// import { styled } from '@mui/material/styles';

// // Professional blue color palette
// const COLORS = [
//   '#2B4C8C', // Primary dark blue
//   '#4169B2', // Secondary blue
//   '#6B8CCE', // Light blue
//   '#89A7E0', // Lighter blue
//   '#A8C0ED', // Softest blue
//   '#DBE6F6'  // Pale blue
// ];

// const RoundedBar = (props) => {
//   const { x, y, width, height, fill } = props;
//   const radius = 4; // Slightly smaller radius for more professional look
//   return (
//     <g>
//       <rect x={x} y={y} width={width} height={height} rx={radius} fill={fill} />
//     </g>
//   );
// };

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   borderRadius: '12px',
//   boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
//   height: '100%',
//   position: 'relative',
//   backgroundColor: '#FFFFFF',
//   '&:hover': {
//     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
//     transition: 'box-shadow 0.3s ease-in-out'
//   }
// }));

// const chartCommonProps = {
//   style: {
//     fontSize: '12px',
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
//   }
// };

// const DataInsights = ({ listing }) => {
//   const theme = useTheme();
  
//   // Check if any data exists across all distributions
//   const hasAnyData = listing && (
//     listing.total_candidates > 0 ||
//     Object.values(listing?.score_distribution || {}).some(val => val > 0) ||
//     Object.values(listing?.sector_distribution || {}).some(val => val > 0) ||
//     Object.values(listing?.school_distribution || {}).some(val => val > 0) ||
//     Object.values(listing?.gpa_distribution || {}).some(val => val > 0) ||
//     Object.values(listing?.degree_distribution || {}).some(val => val > 0)
//   );

//   if (!hasAnyData) {
//     return (
//       <Box sx={{ 
//         p: 3, 
//         display: 'flex', 
//         justifyContent: 'center', 
//         alignItems: 'center',
//         minHeight: '300px'
//       }}>
//         <Alert 
//           severity="info" 
//           sx={{ 
//             width: '100%', 
//             maxWidth: '500px',
//             '& .MuiAlert-message': {
//               width: '100%',
//               textAlign: 'center',
//               fontSize: '1.1rem'
//             }
//           }}
//         >
//           You must make one search to get data insights
//         </Alert>
//       </Box>
//     );
//   }

//   const renderScoreDistributionChart = () => {
//     const scoreData = listing?.score_distribution ? 
//       Object.entries(listing.score_distribution).map(([range, count]) => ({
//         range,
//         count,
//         fill: COLORS[Object.keys(listing.score_distribution).indexOf(range) % COLORS.length]
//       })) : [];

//     const hasData = scoreData.some(item => item.count > 0);

//     return (
//       <StyledPaper>
//         <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
//           Candidate Score Distribution
//         </Typography>
//         <Box sx={{ height: 300, position: 'relative' }}>
//           {!hasData ? (
//             <Box sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               textAlign: 'center'
//             }}>
//               <Alert severity="info">No score data available</Alert>
//             </Box>
//           ) : (
//             <ResponsiveContainer>
//               <BarChart data={scoreData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="range" />
//                 <YAxis />
//                 <Tooltip />
//                 <Bar dataKey="count" shape={<RoundedBar />}>
//                   {scoreData.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={entry.fill} />
//                   ))}
//                 </Bar>
//               </BarChart>
//             </ResponsiveContainer>
//           )}
//         </Box>
//       </StyledPaper>
//     );
//   };

//   const renderSectorChart = () => {
//     const sectorData = listing?.sector_distribution ? 
//       Object.entries(listing.sector_distribution)
//         .map(([name, value]) => ({
//           name,
//           value
//         }))
//         .sort((a, b) => b.value - a.value) : [];

//     const hasData = sectorData.some(item => item.value > 0);

//     return (
//       <StyledPaper>
//         <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
//           Project Sectors
//         </Typography>
//         <Box sx={{ height: 300, position: 'relative' }}>
//           {!hasData ? (
//             <Box sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               textAlign: 'center'
//             }}>
//               <Alert severity="info">No sector data available</Alert>
//             </Box>
//           ) : (
//             <ResponsiveContainer>
//               <PieChart>
//                 <Pie
//                   data={sectorData}
//                   dataKey="value"
//                   nameKey="name"
//                   cx="50%"
//                   cy="50%"
//                   outerRadius={100}
//                   label
//                 >
//                   {sectorData.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                   ))}
//                 </Pie>
//                 <Tooltip />
//                 <Legend />
//               </PieChart>
//             </ResponsiveContainer>
//           )}
//         </Box>
//       </StyledPaper>
//     );
//   };

//   const renderSchoolsChart = () => {
//     const schoolData = listing?.school_distribution ?
//       Object.entries(listing.school_distribution)
//         .map(([name, value]) => ({
//           name,
//           value
//         }))
//         .sort((a, b) => b.value - a.value)
//         .slice(0, 5) : [];

//     const hasData = schoolData.some(item => item.value > 0);

//     return (
//       <StyledPaper>
//         <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
//           Top Schools
//         </Typography>
//         <Box sx={{ height: 300, position: 'relative' }}>
//           {!hasData ? (
//             <Box sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               textAlign: 'center'
//             }}>
//               <Alert severity="info">No school data available</Alert>
//             </Box>
//           ) : (
//             <ResponsiveContainer>
//               <BarChart data={schoolData} layout="vertical">
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis type="number" />
//                 <YAxis dataKey="name" type="category" width={150} />
//                 <Tooltip />
//                 <Bar dataKey="value" fill={COLORS[0]} shape={<RoundedBar />} />
//               </BarChart>
//             </ResponsiveContainer>
//           )}
//         </Box>
//       </StyledPaper>
//     );
//   };

//   const renderGpaChart = () => {
//     const gpaData = listing?.gpa_distribution ? 
//       Object.entries(listing.gpa_distribution)
//         .map(([range, count]) => ({
//           range,
//           count
//         })) : [];

//     const hasData = gpaData.some(item => item.count > 0);

//     return (
//       <StyledPaper>
//         <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
//           GPA Distribution
//         </Typography>
//         <Box sx={{ height: 300, position: 'relative' }}>
//           {!hasData ? (
//             <Box sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               textAlign: 'center'
//             }}>
//               <Alert severity="info">No GPA data available</Alert>
//             </Box>
//           ) : (
//             <ResponsiveContainer>
//               <BarChart data={gpaData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="range" />
//                 <YAxis />
//                 <Tooltip />
//                 <Bar dataKey="count" shape={<RoundedBar />}>
//                   {gpaData.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                   ))}
//                 </Bar>
//               </BarChart>
//             </ResponsiveContainer>
//           )}
//         </Box>
//       </StyledPaper>
//     );
//   };

//   const renderDegreeChart = () => {
//     const degreeData = listing?.degree_distribution ?
//       Object.entries(listing.degree_distribution)
//         .map(([name, value]) => ({
//           name: name.replace('_', ' '),
//           value
//         }))
//         .filter(item => item.value > 0) : [];

//     const hasData = degreeData.length > 0;

//     return (
//       <StyledPaper>
//         <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
//           Degree Distribution
//         </Typography>
//         <Box sx={{ height: 300, position: 'relative' }}>
//           {!hasData ? (
//             <Box sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               textAlign: 'center'
//             }}>
//               <Alert severity="info">No degree data available</Alert>
//             </Box>
//           ) : (
//             <ResponsiveContainer>
//               <PieChart>
//                 <Pie
//                   data={degreeData}
//                   dataKey="value"
//                   nameKey="name"
//                   cx="50%"
//                   cy="50%"
//                   outerRadius={80}
//                   label
//                 >
//                   {degreeData.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                   ))}
//                 </Pie>
//                 <Tooltip />
//                 <Legend />
//               </PieChart>
//             </ResponsiveContainer>
//           )}
//         </Box>
//       </StyledPaper>
//     );
//   };

//   const renderTotalCandidates = () => (
//     <StyledPaper>
//       <Typography variant="h6" sx={{ mb: 2, color: '#2B4C8C', fontWeight: 600 }}>
//         Total Candidates
//       </Typography>
//       <Box sx={{ p: 3, textAlign: 'center' }}>
//         <Typography variant="h2" sx={{ color: COLORS[0], fontWeight: 600 }}>
//           {listing?.total_candidates || 0}
//         </Typography>
//       </Box>
//     </StyledPaper>
//   );

//   return (
//     <Box sx={{ p: 3 }}>
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={4}>
//           {renderScoreDistributionChart()}
//         </Grid>
//         <Grid item xs={12} md={4}>
//           {renderSectorChart()}
//         </Grid>
//         <Grid item xs={12} md={4}>
//           {renderTotalCandidates()}
//         </Grid>
//         <Grid item xs={12} md={4}>
//           {renderSchoolsChart()}
//         </Grid>
//         <Grid item xs={12} md={4}>
//           {renderGpaChart()}
//         </Grid>
//         <Grid item xs={12} md={4}>
//           {renderDegreeChart()}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default DataInsights;