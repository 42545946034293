// File: src/components/Auth/VerificationPage.js

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Alert,
  CircularProgress,
  Typography,
  Paper
} from '@mui/material';
import { styled } from '@mui/system';
import { verifyEmail } from '../../services/authSlice';
import '@fontsource/plus-jakarta-sans';

/* IMAGES (Adjust paths if needed) */
import verifyBackground from '../../assets/auth_background.jpg';
import logo from '../../assets/logo.png';

const VerificationContainer = styled(Grid)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
}));

const LeftPanel = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${verifyBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BrandingBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: '#fff',
}));

const RightPanel = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const VerificationWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
  width: '100%',
  textAlign: 'center',
  border: '1px solid #eee',
  borderRadius: '8px',
  padding: theme.spacing(4),
  boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
}));

const VerificationPage = () => {
  const [status, setStatus] = useState('loading');
  const [showError, setShowError] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await dispatch(verifyEmail(token)).unwrap();
        setStatus('success');
        setTimeout(() => {
          navigate('/login', {
            state: {
              verificationSuccess: true,
              message: 'Email verified successfully! Please log in.',
            },
          });
        }, 3000);
      } catch (error) {
        setTimeout(() => {
          setStatus('error');
          setShowError(true);
        }, 2000);
      }
    };

    if (token) {
      verifyToken();
    }
  }, [token, dispatch, navigate]);

  return (
    <VerificationContainer container>
      {/* LEFT PANEL */}
      <Grid item xs={12} md={6}>
        <LeftPanel>
          <Overlay>
            <BrandingBox>
              <Box
                component="img"
                src={logo}
                alt="Intratalent AI"
                sx={{ width: '150px', mb: 2 }}
              />
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                intratalent ai
              </Typography>
            </BrandingBox>
          </Overlay>
        </LeftPanel>
      </Grid>

      {/* RIGHT PANEL */}
      <RightPanel item xs={12} md={6}>
        <VerificationWrapper>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: 4,
              color: '#1A365D',
            }}
          >
            Email Verification
          </Typography>

          {status === 'loading' && (
            <>
              <CircularProgress sx={{ color: '#1A365D', mb: 2 }} />
              <Typography variant="body1" sx={{ color: '#333' }}>
                Verifying your email...
              </Typography>
            </>
          )}

          {status === 'success' && (
            <Alert severity="success" sx={{ mb: 2 }}>
              Email verified successfully! Redirecting to login...
            </Alert>
          )}

          {status === 'error' && showError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Failed to verify email. The link may be invalid or expired.
              Please try signing up again or contact support.
            </Alert>
          )}
        </VerificationWrapper>
      </RightPanel>
    </VerificationContainer>
  );
};

export default VerificationPage;



// import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
// import {
//   Box,
//   Paper,
//   Alert,
//   CircularProgress,
//   Typography
// } from '@mui/material';
// import { verifyEmail } from '../../services/authSlice';
// import { styled } from '@mui/system';
// import '@fontsource/plus-jakarta-sans';
// import backgroundVideo from '../../assets/background.mov';

// const BackgroundContainer = styled(Box)(({ theme }) => ({
//   position: 'relative',
//   minHeight: '100vh',
//   overflow: 'hidden',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   fontFamily: '"Plus Jakarta Sans", sans-serif',
// }));

// const VideoBackground = styled('video')({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   minWidth: '100%',
//   minHeight: '100%',
//   width: 'auto',
//   height: 'auto',
//   zIndex: -1,
//   transform: 'translate(-50%, -50%)',
//   objectFit: 'cover',
// });

// const FormContainer = styled(Box)(({ theme }) => ({
//   maxWidth: 400,
//   width: '100%',
//   backgroundColor: 'rgba(255, 255, 255, 0.85)',
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
//   zIndex: 1,
//   textAlign: 'center',
// }));

// const VerificationPage = () => {
//   const [status, setStatus] = useState('loading');
//   const [showError, setShowError] = useState(false);
//   const { token } = useParams();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
//         await dispatch(verifyEmail(token)).unwrap();
//         setStatus('success');
//         setTimeout(() => {
//           navigate('/login', { 
//             state: { 
//               verificationSuccess: true,
//               message: 'Email verified successfully! Please log in.' 
//             }
//           });
//         }, 3000);
//       } catch (error) {
//         // Only show error after a delay
//         setTimeout(() => {
//           setStatus('error');
//           setShowError(true);
//         }, 2000);
//       }
//     };

//     if (token) {
//       verifyToken();
//     }
//   }, [token, dispatch, navigate]);

//   return (
//     <BackgroundContainer>
//       <VideoBackground autoPlay muted loop>
//         <source src={backgroundVideo} type="video/mp4" />
//         Your browser does not support the video tag.
//       </VideoBackground>
//       <FormContainer>
//         <Typography
//           variant="h4"
//           sx={{
//             fontWeight: 700,
//             marginBottom: 4,
//             color: '#1A365D', // Changed to dark blue
//             fontFamily: '"Plus Jakarta Sans", sans-serif',
//           }}
//         >
//           Email Verification
//         </Typography>

//         {status === 'loading' && (
//           <>
//             <CircularProgress sx={{ color: '#6FA8DC', mb: 2 }} />
//             <Typography variant="body1" sx={{ color: '#4A5568' }}>
//               Verifying your email...
//             </Typography>
//           </>
//         )}

//         {status === 'success' && (
//           <Alert severity="success" sx={{ mb: 2 }}>
//             Email verified successfully! Redirecting to login...
//           </Alert>
//         )}

//         {status === 'error' && showError && (
//           <Alert severity="error" sx={{ mb: 2 }}>
//             Failed to verify email. The link may be expired or invalid.
//             Please try signing up again or contact support.
//           </Alert>
//         )}
//       </FormContainer>
//     </BackgroundContainer>
//   );
// };

// export default VerificationPage;