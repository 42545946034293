import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
  Paper,
  Grid,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { initiateCreditPurchase, completeCreditPurchase, fetchCreditBalance, clearCreditPurchase } from '../../services/listingSlice';
import { stripePromise } from './stripe';

const CreditDisplay = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderRadius: '12px',
  background: 'linear-gradient(135deg, #3B6AC1 0%, #2B4C8C 100%)',
  color: 'white',
}));

const CreditPackage = styled(Paper)(({ theme, selected }) => ({
  padding: theme.spacing(3),
  cursor: 'pointer',
  textAlign: 'center',
  borderRadius: '12px',
  border: selected ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const CreditPaymentForm = ({ onSuccess, onError, numCredits }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
      });

      if (error) throw new Error(error.message);

      await dispatch(completeCreditPurchase({
        paymentIntentId: paymentIntent.id
      })).unwrap();
      
      onSuccess();
    } catch (err) {
      onError(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        disabled={!stripe || processing}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
      >
        {processing ? 'Processing...' : `Purchase ${numCredits} Applicants`}
      </Button>
    </form>
  );
};

export const CreditManager = () => {
  const dispatch = useDispatch();
  const { credits, creditPurchaseIntent, pendingCredits } = useSelector(state => state.listings);
  const [purchaseDialogOpen, setPurchaseDialogOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    dispatch(fetchCreditBalance());
  }, [dispatch]);

  const creditPackages = [
    { credits: 25, price: 5 },
    { credits: 100, price: 20 },
    { credits: 250, price: 50 }
  ];

  const handlePurchaseClick = async (packageDetails) => {
    setSelectedPackage(packageDetails);
    try {
      await dispatch(initiateCreditPurchase(packageDetails.credits)).unwrap();
    } catch (err) {
      console.error('Failed to initiate credit purchase:', err);
    }
  };

  const handleCloseDialog = () => {
    setPurchaseDialogOpen(false);
    setSelectedPackage(null);
    dispatch(clearCreditPurchase());
  };

  return (
    <>
      <Box>
        <CreditDisplay>
          <PersonIcon sx={{ fontSize: 32 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {credits} Applicants Left
            </Typography>
            <Typography variant="body2">
              for candidate uploads
            </Typography>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setPurchaseDialogOpen(true)}
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.9)',
              color: '#3B6AC1',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 1)',
              }
            }}
          >
            Increase limit
          </Button>
        </CreditDisplay>
      </Box>

      <Dialog
        open={purchaseDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Add applicants for upload
          <IconButton
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!creditPurchaseIntent ? (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {creditPackages.map((pkg) => (
                <Grid item xs={12} md={4} key={pkg.credits}>
                  <CreditPackage
                    selected={selectedPackage?.credits === pkg.credits}
                    onClick={() => handlePurchaseClick(pkg)}
                  >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {pkg.credits} 
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Applicants 
                    </Typography>
                    <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                      ${pkg.price}
                    </Typography>
                    <Button
                      variant={selectedPackage?.credits === pkg.credits ? "contained" : "outlined"}
                      fullWidth
                    >
                      Select
                    </Button>
                  </CreditPackage>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: creditPurchaseIntent,
                appearance: { theme: 'stripe' }
              }}
            >
              <CreditPaymentForm
                onSuccess={() => {
                  handleCloseDialog();
                  dispatch(fetchCreditBalance());
                }}
                onError={(error) => {
                  console.error(error);
                }}
                numCredits={pendingCredits}
              />
            </Elements>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};