import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

export const fetchListings = createAsyncThunk(
  'listings/fetchListings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getListings();
      return response.listings;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to fetch listings');
    }
  }
);

export const validatePromoCode = createAsyncThunk(
  'listings/validatePromoCode',
  async (promoCode, { rejectWithValue }) => {
    try {
      const response = await api.validatePromoCode(promoCode);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to validate promo code');
    }
  }
);

export const initiateListingCreation = createAsyncThunk(
  'listings/initiateListingCreation',
  async ({ listingData, promoCode }, { rejectWithValue }) => {
    try {
      const paymentData = {
        listing_data: listingData
      };
      
      if (promoCode) {
        paymentData.promo_code = promoCode;
      }
      
      const response = await api.handleListingCreation(paymentData);
      return {
        clientSecret: response.clientSecret,
        amount: response.amount,
        listingData
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to initiate listing creation');
    }
  }
);

export const completeListingCreation = createAsyncThunk(
  'listings/completeListingCreation',
  async ({ paymentIntentId, listingData }, { rejectWithValue }) => {
    try {
      // Handle free listings differently
      if (paymentIntentId === 'free') {
        const response = await api.createFreeListing(listingData);
        return {
          id: response.listing_id,
          title: listingData.title,
          job_description: listingData.job_description,
          namespace: listingData.namespace || 'all',
          payment_status: 'completed'
        };
      }

      const response = await api.confirmPaymentAndCreateListing(paymentIntentId, listingData);
      return {
        id: response.listing_id,
        title: listingData.title,
        job_description: listingData.job_description,
        namespace: listingData.namespace || 'all',
        payment_status: 'completed'
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to complete listing creation');
    }
  }
);

export const updateListing = createAsyncThunk(
  'listings/updateListing',
  async ({ listingId, listingData }, { rejectWithValue }) => {
    try {
      const response = await api.updateListing(listingId, listingData);
      return { id: listingId, ...listingData };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to update listing');
    }
  }
);

export const deleteListing = createAsyncThunk(
  'listings/deleteListing',
  async (listingId, { rejectWithValue }) => {
    try {
      await api.deleteListing(listingId);
      return listingId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to delete listing');
    }
  }
);

export const fetchCreditBalance = createAsyncThunk(
  'listings/fetchCreditBalance',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getCreditBalance();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to fetch credit balance');
    }
  }
);

export const initiateCreditPurchase = createAsyncThunk(
  'listings/initiateCreditPurchase',
  async (creditAmount, { rejectWithValue }) => {
    try {
      const response = await api.createCreditPayment(creditAmount);
      return {
        clientSecret: response.clientSecret,
        amount: response.amount,
        numCredits: response.num_credits
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to initiate credit purchase');
    }
  }
);

export const completeCreditPurchase = createAsyncThunk(
  'listings/completeCreditPurchase',
  async ({ paymentIntentId }, { rejectWithValue }) => {
    try {
      const response = await api.confirmCreditPayment(paymentIntentId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error || 'Failed to complete credit purchase');
    }
  }
);

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {
    list: [],
    selectedListing: null,
    loading: false,
    error: null,
    paymentIntent: null,
    pendingListingData: null,
    paymentStatus: null,
    promoCodeStatus: null,
    promoCodeDiscount: null,
    amount: null,
    distributions: null,
    credits: 0,
    creditTransactions: [],
    creditPurchaseIntent: null,
    pendingCredits: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setSelectedListing: (state, action) => {
      state.selectedListing = action.payload;
    },
    clearPaymentIntent: (state) => {
      state.paymentIntent = null;
      state.pendingListingData = null;
      state.paymentStatus = null;
      state.promoCodeStatus = null;
      state.promoCodeDiscount = null;
      state.amount = null;
    },
    clearPromoCode: (state) => {
      state.promoCodeStatus = null;
      state.promoCodeDiscount = null;
    },
    updateDistributions: (state, action) => {
      const { listingId, distributions } = action.payload;
      const listing = state.list.find(l => l.id === listingId);
      if (listing) {
        listing.score_distribution = distributions.score_distribution;
        listing.gpa_distribution = distributions.gpa_distribution;
        listing.total_candidates = distributions.total_candidates;
      }
    },
    clearCreditPurchase: (state) => {
      state.creditPurchaseIntent = null;
      state.pendingCredits = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch listings cases
      .addCase(fetchListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchListings.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchListings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Cases for promo code validation
      .addCase(validatePromoCode.pending, (state) => {
        state.promoCodeStatus = 'validating';
        state.error = null;
      })
      .addCase(validatePromoCode.fulfilled, (state, action) => {
        state.promoCodeStatus = 'valid';
        state.promoCodeDiscount = action.payload.discount;
      })
      .addCase(validatePromoCode.rejected, (state, action) => {
        state.promoCodeStatus = 'invalid';
        state.error = action.payload;
        state.promoCodeDiscount = null;
      })
      
      // Initiate listing creation cases
      .addCase(initiateListingCreation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.paymentStatus = 'pending';
      })
      .addCase(initiateListingCreation.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIntent = action.payload.clientSecret;
        state.pendingListingData = action.payload.listingData;
        state.paymentStatus = 'awaiting_payment';
        state.amount = action.payload.amount;
      })
      .addCase(initiateListingCreation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentStatus = 'failed';
      })
      
      // Complete listing creation cases
      .addCase(completeListingCreation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeListingCreation.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
        state.paymentIntent = null;
        state.pendingListingData = null;
        state.paymentStatus = 'completed';
      })
      .addCase(completeListingCreation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentStatus = 'failed';
      })
      
      // Update listing cases
      .addCase(updateListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateListing.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(listing => listing.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Delete listing cases
      .addCase(deleteListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteListing.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter(listing => listing.id !== action.payload);
      })
      .addCase(deleteListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Credit purchase cases
      .addCase(fetchCreditBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreditBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.credits = action.payload.credits;
        state.creditTransactions = action.payload.credit_transactions;
      })
      .addCase(fetchCreditBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addCase(initiateCreditPurchase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initiateCreditPurchase.fulfilled, (state, action) => {
        state.loading = false;
        state.creditPurchaseIntent = action.payload.clientSecret;
        state.pendingCredits = action.payload.numCredits;
      })
      .addCase(initiateCreditPurchase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addCase(completeCreditPurchase.fulfilled, (state, action) => {
        state.credits = action.payload.current_credits;
        state.creditPurchaseIntent = null;
        state.pendingCredits = null;
      });
  }
});

export const { clearError, setSelectedListing, clearPaymentIntent, clearPromoCode, clearCreditPurchase } = listingsSlice.actions;
export default listingsSlice.reducer;