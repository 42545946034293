// File: src/components/Dashboard/DashboardLayout.js

import React, { useState } from 'react';
import { Box, Button, styled, useTheme, useMediaQuery, Drawer, IconButton, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/authSlice';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  background: '#fff',
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#0747A6',
}));

const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
  cursor: 'pointer',
  '& span': {
    fontSize: '24px',
    fontWeight: 600,
    color: 'white',
  },
  '&:hover': {
    opacity: 0.8,
  },
}));

const NavItem = styled(Button)(({ theme, active }) => ({
  color: 'white',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.5, 2),
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '16px',
  backgroundColor: active ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const MainContent = styled(Box)(({ theme, isSidebarOpen }) => ({
  flex: 1,
  backgroundColor: '#F4F5F7',
  overflowY: 'auto',
  marginLeft: isSidebarOpen ? 280 : 0,
  transition: 'margin 0.3s ease-in-out',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    paddingTop: '64px', // Space for mobile menu button
  },
}));

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleContentClick = () => {
    if (isMobile && sidebarOpen) {
      setSidebarOpen(false);
    }
  };

  return (
    <StyledContainer>
      {/* Mobile Menu Button - now positioned bottom left */}
      {isMobile && (
        <IconButton
          onClick={toggleSidebar}
          sx={{
            position: 'fixed',
            left: 16,
            bottom: 16,  // Changed from top to bottom
            zIndex: 9999,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            width: 40,
            height: 40,
            '&:hover': {
              backgroundColor: '#F3F4F6',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Sidebar/Drawer */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        PaperProps={{
          sx: {
            width: isMobile ? 240 : 280,
            border: 'none',
            backgroundColor: '#0747A6',
          },
        }}
      >
        <DrawerContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Logo onClick={() => navigate('/')}>
              <span>Intratalent ai</span>
            </Logo>
            {isMobile && (
              <IconButton 
                onClick={() => setSidebarOpen(false)}
                sx={{ color: 'white' }}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Box>

          <NavItem 
            active={location.pathname === '/dashboard'}
            onClick={() => {
              navigate('/dashboard');
              if (isMobile) setSidebarOpen(false);
            }}
          >
            <DashboardIcon sx={{ mr: 2 }} />
            Dashboard
          </NavItem>
          <NavItem 
            active={location.pathname.includes('/search')}
            onClick={() => {
              navigate('/search/all');
              if (isMobile) setSidebarOpen(false);
            }}
          >
            <SearchIcon sx={{ mr: 2 }} />
            Search All Candidates
          </NavItem>
          <NavItem 
            onClick={() => {
              navigate('/dashboard');
              if (isMobile) setSidebarOpen(false);
            }}
          >
            <AddIcon sx={{ mr: 2 }} />
            Create Listings
          </NavItem>
          <NavItem>
            <HistoryIcon sx={{ mr: 2 }} />
            History - Coming Soon!
          </NavItem>
          <Box sx={{ flexGrow: 1 }} />
          <NavItem 
            onClick={() => {
              dispatch(logout());
              if (isMobile) setSidebarOpen(false);
            }}
          >
            <LogoutIcon sx={{ mr: 2 }} />
            Log out
          </NavItem>
        </DrawerContent>
      </Drawer>

      <MainContent 
        isSidebarOpen={!isMobile && sidebarOpen} 
        onClick={handleContentClick}
        sx={{ 
          paddingBottom: isMobile ? '64px' : undefined // Add padding to prevent content from being hidden behind the button
        }}
      >
        {children}
      </MainContent>
    </StyledContainer>
  );
};

export default DashboardLayout;