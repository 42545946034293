// File: src/components/Settings/WeightSliders.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  IconButton,
  Slider,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const CriteriaCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
}));

const CriteriaHeader = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #E2E8F0',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const CriteriaBody = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const CriteriaItem = styled(Paper)(({ theme }) => ({
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#3B6AC1',
  '& .MuiSlider-rail': {
    opacity: 0.3,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
    border: '2px solid #3B6AC1',
  },
}));

const GPAContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  padding: theme.spacing(2),
}));

const ButtonRow = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  marginTop: theme.spacing(2),
}));

function WeightSliders({
  experiences,
  onWeightsChange,
  onSearch,
  searchLoading,
  onGpaChange,
}) {
  const [weights, setWeights] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [minGpa, setMinGpa] = useState(3.7);
  const [gpaInputValue, setGpaInputValue] = useState('3.70');

  useEffect(() => {
    if (experiences && experiences.length > 0) {
      const newWeights = experiences.map((exp) => ({
        description: exp[0],
        weight: Math.round(exp[1] * 100),
      }));
      setWeights(newWeights);
      setHasChanges(false);
    }
  }, [experiences]);

  const updateParent = (updatedWeights) => {
    if (onWeightsChange) {
      const normalized = updatedWeights.map((w) => [w.description, w.weight / 100]);
      onWeightsChange(normalized);
    }
  };

  const handleAddCriterion = () => {
    const updated = [...weights, { description: 'New Criterion', weight: 0 }];
    setWeights(updated);
    setHasChanges(true);
    updateParent(updated);
  };

  const handleDeleteCriterion = (index) => {
    const updated = weights.filter((_, i) => i !== index);
    setWeights(updated);
    setHasChanges(true);
    updateParent(updated);
  };

  const handleDescriptionChange = (index, newDesc) => {
    const updated = [...weights];
    updated[index].description = newDesc;
    setWeights(updated);
    setHasChanges(true);
    updateParent(updated);
  };

  const handleSliderChange = (index, value) => {
    const updated = [...weights];
    if (typeof value === 'string') {
      updated[index].weight = parseInt(value, 10) || 0;
    } else {
      updated[index].weight = Math.round(value);
    }
    setWeights(updated);
    setHasChanges(true);
    updateParent(updated);
  };

  const handleBlur = (index) => {
    if (weights[index].weight < 0) handleSliderChange(index, 0);
    if (weights[index].weight > 100) handleSliderChange(index, 100);
  };

  // GPA
  const handleGpaInputChange = (e) => {
    const val = e.target.value;
    setGpaInputValue(val);
    const numericVal = parseFloat(val);
    if (!isNaN(numericVal) && numericVal >= 0 && numericVal <= 4.0) {
      setMinGpa(numericVal);
      onGpaChange?.(numericVal);
    }
  };

  const handleGpaSliderChange = (_, value) => {
    setMinGpa(value);
    setGpaInputValue(value.toFixed(2));
    onGpaChange?.(value);
  };

  return (
    <CriteriaCard sx={{ marginTop: 2 }}>
      <CriteriaHeader>
        <Typography sx={{ fontWeight: 700, color: '#1A365D', fontSize: '16px' }}>
          Ideal Candidate Criteria
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {hasChanges && (
            <Typography
              sx={{
                fontSize: '14px',
                color: '#3B6AC1',
                fontStyle: 'italic',
              }}
            >
              Changes pending
            </Typography>
          )}
          <IconButton
            onClick={handleAddCriterion}
            sx={{
              color: '#3B6AC1',
              '&:hover': { backgroundColor: 'rgba(59,106,193,0.1)' },
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </CriteriaHeader>

      <CriteriaBody>
        {weights.map((item, idx) => (
          <CriteriaItem key={idx}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={item.description}
                onChange={(e) => handleDescriptionChange(idx, e.target.value)}
              />
              <IconButton
                onClick={() => handleDeleteCriterion(idx)}
                sx={{
                  color: '#DC2626',
                  '&:hover': { backgroundColor: 'rgba(220,38,38,0.1)' },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <StyledSlider
                  min={0}
                  max={100}
                  step={1}
                  value={item.weight}
                  onChange={(_, val) => handleSliderChange(idx, val)}
                  onChangeCommitted={() => handleBlur(idx)}
                />
              </Box>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                value={item.weight}
                onChange={(e) => handleSliderChange(idx, e.target.value)}
                onBlur={() => handleBlur(idx)}
                sx={{ width: 70 }}
                inputProps={{ min: 0, max: 100 }}
              />
              <Typography sx={{ fontSize: '14px', color: '#4A5568' }}>%</Typography>
            </Box>
          </CriteriaItem>
        ))}

        {/* GPA */}
        <GPAContainer>
          <Typography sx={{ fontWeight: 600, color: '#1A365D', mb: 1 }}>
            Minimum Preferred GPA
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={gpaInputValue}
              onChange={handleGpaInputChange}
              inputProps={{ min: 0, max: 4.0, step: 0.01 }}
              sx={{ width: 80 }}
            />
            <Typography sx={{ fontSize: '13px', color: '#4A5568' }}>(0.00 - 4.00)</Typography>
          </Box>
          <StyledSlider
            min={0}
            max={4.0}
            step={0.01}
            value={minGpa}
            onChange={handleGpaSliderChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(val) => val.toFixed(2)}
          />
        </GPAContainer>

        <ButtonRow>
          <Button
            variant="contained"
            onClick={onSearch}
            disabled={searchLoading}
            startIcon={searchLoading ? <CircularProgress size={20} /> : <SearchIcon />}
            sx={{
              backgroundColor: '#3B6AC1',
              color: '#FFF',
              fontWeight: 600,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#2B4C8C',
              },
            }}
          >
            IntraSearch
          </Button>
        </ButtonRow>
      </CriteriaBody>
    </CriteriaCard>
  );
}

export default WeightSliders;




// import React, { useState, useEffect } from 'react';
// import { 
//   Card, 
//   CardContent, 
//   Typography, 
//   Slider, 
//   Box, 
//   Paper, 
//   TextField, 
//   Button, 
//   CircularProgress,
//   IconButton
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';

// const StyledCard = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: '800px',
//   margin: '24px auto',
//   background: '#ffffff',
//   borderRadius: '16px',
//   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
//   '&:hover': {
//     boxShadow: '0 6px 8px rgba(0, 0, 0, 0.1)',
//   },
// }));

// const StyledSlider = styled(Slider)(({ theme }) => ({
//   color: '#3B6AC1',
//   '& .MuiSlider-thumb': {
//     '&:hover, &.Mui-focusVisible': {
//       boxShadow: '0 0 0 8px rgba(59, 106, 193, 0.16)',
//     },
//   },
//   '& .MuiSlider-rail': {
//     opacity: 0.32,
//   },
// }));

// const WeightSliders = ({ experiences, onWeightsChange, onSearch, searchLoading, onGpaChange }) => {
//   const [weights, setWeights] = useState([]);
//   const [hasChanges, setHasChanges] = useState(false);
//   const [minGpa, setMinGpa] = useState(3.70);
//   const [gpaInputValue, setGpaInputValue] = useState("3.70");

//   useEffect(() => {
//     if (experiences && experiences.length > 0) {
//       setWeights(experiences.map(exp => ({
//         description: exp[0],
//         weight: Math.round(exp[1] * 100),
//       })));
//       setHasChanges(false);
//     }
//   }, [experiences]);

//   const handleWeightChange = (index, newValue) => {
//     const newWeights = [...weights];
//     if (typeof newValue === 'string') {
//       const cleanValue = newValue.replace(/\./g, '');
//       newWeights[index].weight = cleanValue === '' ? '' : parseInt(cleanValue) || 0;
//     } else {
//       newWeights[index].weight = Math.round(newValue);
//     }
//     updateWeights(newWeights);
//   };

//   const handleDescriptionChange = (index, newDescription) => {
//     const newWeights = [...weights];
//     newWeights[index].description = newDescription;
//     updateWeights(newWeights);
//   };

//   const handleAddCriteria = () => {
//     const newWeights = [...weights, {
//       description: "New criteria",
//       weight: 0
//     }];
//     updateWeights(newWeights);
//   };

//   const handleDeleteCriteria = (index) => {
//     const newWeights = weights.filter((_, i) => i !== index);
//     updateWeights(newWeights);
//   };

//   const updateWeights = (newWeights) => {
//     setWeights(newWeights);
//     setHasChanges(true);

//     if (onWeightsChange) {
//       const normalizedWeights = newWeights.map(w => [
//         w.description, 
//         w.weight === '' ? 0 : w.weight / 100
//       ]);
//       onWeightsChange(normalizedWeights);
//     }
//   };

//   const handleBlur = (index) => {
//     const newWeights = [...weights];
//     if (newWeights[index].weight === '') {
//       newWeights[index].weight = 0;
//       updateWeights(newWeights);
//     }
//   };

//   const handleGpaInputChange = (e) => {
//     const value = e.target.value;
//     setGpaInputValue(value);
    
//     const numValue = parseFloat(value);
//     if (!isNaN(numValue) && numValue >= 0 && numValue <= 4.0) {
//       setMinGpa(numValue);
//       onGpaChange(numValue);
//     }
//   };

//   return (
//     <StyledCard>
//       <CardContent>
//         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
//           <Typography
//             variant="h6"
//             sx={{
//               fontWeight: 600,
//               color: '#2B4C8C',
//               fontFamily: '"Plus Jakarta Sans", sans-serif',
//             }}
//           >
//             Ideal Candidate Criteria
//           </Typography>
          
//           <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//             {hasChanges && (
//               <Typography
//                 sx={{
//                   fontSize: '0.875rem',
//                   color: '#3B6AC1',
//                   fontFamily: '"Plus Jakarta Sans", sans-serif',
//                   fontStyle: 'italic'
//                 }}
//               >
//                 Changes pending
//               </Typography>
//             )}
//             <IconButton
//               onClick={handleAddCriteria}
//               sx={{
//                 color: '#3B6AC1',
//                 '&:hover': {
//                   backgroundColor: 'rgba(59, 106, 193, 0.1)',
//                 }
//               }}
//             >
//               <AddIcon />
//             </IconButton>
//           </Box>
//         </Box>

//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
//           {weights.map((weight, index) => (
//             <Paper
//               key={index}
//               elevation={0}
//               sx={{
//                 p: 2,
//                 backgroundColor: 'rgba(248, 250, 255, 0.8)',
//                 borderRadius: 2,
//               }}
//             >
//               <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center', gap: 2 }}>
//                 <TextField
//                   value={weight.description}
//                   onChange={(e) => handleDescriptionChange(index, e.target.value)}
//                   variant="outlined"
//                   size="small"
//                   fullWidth
//                   sx={{
//                     '& .MuiOutlinedInput-root': {
//                       fontSize: '0.875rem',
//                       color: '#4A5568',
//                       fontFamily: '"Plus Jakarta Sans", sans-serif',
//                     }
//                   }}
//                 />
//                 <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                   <TextField
//                     value={weight.weight}
//                     onChange={(e) => handleWeightChange(index, e.target.value)}
//                     onBlur={() => handleBlur(index)}
//                     variant="outlined"
//                     size="small"
//                     InputProps={{
//                       inputProps: { 
//                         min: 0, 
//                         max: 100,
//                         step: 1
//                       },
//                       endAdornment: <Typography>%</Typography>,
//                     }}
//                     type="number"
//                     sx={{ width: '80px' }}
//                   />
//                   <IconButton
//                     onClick={() => handleDeleteCriteria(index)}
//                     sx={{
//                       color: '#DC2626',
//                       '&:hover': {
//                         backgroundColor: 'rgba(220, 38, 38, 0.1)',
//                       }
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </Box>
//               </Box>
//               <StyledSlider
//                 value={weight.weight === '' ? 0 : weight.weight}
//                 onChange={(_, value) => handleWeightChange(index, value)}
//                 step={1}
//                 min={0}
//                 max={100}
//                 aria-label="Weight"
//               />
//             </Paper>
//           ))}
//         </Box>

//         <Box sx={{ 
//           width: '100%', 
//           maxWidth: '800px',
//           margin: '0 auto',
//           padding: 2,
//           backgroundColor: 'rgba(248, 250, 255, 0.8)',
//           borderRadius: 2,
//           marginTop: 2,
//           boxSizing: 'border-box',
//           overflow: 'hidden'
//         }}>
//           <Box sx={{ 
//             display: 'flex', 
//             alignItems: 'center', 
//             justifyContent: 'space-between', 
//             mb: 1,
//             flexWrap: 'wrap',
//             gap: 2
//           }}>
//             <Typography
//               sx={{
//                 color: '#2B4C8C',
//                 fontWeight: 600,
//                 fontFamily: '"Plus Jakarta Sans", sans-serif',
//                 flex: '1 1 auto'
//               }}
//             >
//               Minimum Preferred GPA
//             </Typography>
//             <TextField
//               value={gpaInputValue}
//               onChange={handleGpaInputChange}
//               variant="outlined"
//               size="small"
//               type="number"
//               InputProps={{
//                 inputProps: { 
//                   min: 0, 
//                   max: 4.0,
//                   step: 0.01
//                 }
//               }}
//               sx={{ 
//                 width: '100px',
//                 flex: '0 0 auto'
//               }}
//             />
//           </Box>
//           <Box sx={{ px: 1, width: '100%' }}>
//             <StyledSlider
//               value={minGpa}
//               onChange={(_, newValue) => {
//                 setMinGpa(newValue);
//                 setGpaInputValue(newValue.toFixed(2));
//                 onGpaChange(newValue);
//               }}
//               step={0.01}
//               min={0}
//               max={4.0}
//               aria-label="Minimum GPA"
//               valueLabelDisplay="auto"
//               valueLabelFormat={(value) => value.toFixed(2)}
//             />
//           </Box>
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
//           <Button
//             variant="contained"
//             onClick={onSearch}
//             disabled={searchLoading}
//             startIcon={searchLoading ? <CircularProgress size={20} /> : <SearchIcon />}
//             sx={{
//               backgroundColor: '#3B6AC1',
//               '&:hover': {
//                 backgroundColor: '#2B4C8C',
//               },
//               borderRadius: '12px',
//               padding: '10px 24px',
//               textTransform: 'none',
//               fontFamily: '"Plus Jakarta Sans", sans-serif',
//               fontWeight: 600,
//             }}
//           >
//             IntraSearch
//           </Button>
//         </Box>
//       </CardContent>
//     </StyledCard>
//   );
// };

// export default WeightSliders;