// File: src/App.js

import { Provider } from 'react-redux';
import { store } from './services/store';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './services/authSlice';
import Home from './components/Home/Home';
import ResultsDisplay from './components/ResultsDisplay/ResultsDisplay';
import ResumeUpload from './components/ResumeUpload/ResumeUpload';
import Dashboard from './components/Dashboard/Dashboard';
import AuthPage from './components/Auth/AuthPage';
import VerificationPage from './components/Auth/VerificationPage';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';

import {
  Box,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Container
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from './assets/logo.png';
import '@fontsource/plus-jakarta-sans';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';

const theme = createTheme({
  palette: {
    primary: { main: '#2C3E50' },
    secondary: { main: '#2980B9' },
    success: { main: '#28a745' },
    warning: { main: '#ffc107' },
    error: { main: '#dc3545' },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
  },
});

const ContentOffset = styled('div')({
  height: '64px', // Height of the AppBar
});

// Protected route
const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

// Navigation (Navbar)
const Navigation = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleMobileMenuOpen = (event) => setMobileMenuAnchor(event.currentTarget);
  const handleMobileMenuClose = () => setMobileMenuAnchor(null);

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    handleMobileMenuClose();
  };

  const navItems = [
    { label: 'Team', href: '#team' },
    { label: 'Demo', path: '/demo' },
    { label: 'Dashboard', path: '/dashboard' },
  ];

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          zIndex: 1300
        }}
      >
        <Toolbar sx={{ padding: 0 }}>
          <Container 
            maxWidth={false}
            sx={{
              maxWidth: '1500px',
              width: '100%',
              px: { xs: 2, md: 4 },
              py: 1,
              margin: '0 auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {/* Logo */}
              <Box
                component={Link}
                to="/"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <Box component="img" src={logo} alt="Logo" sx={{ height: '40px' }} />
                <Typography
                  variant="h6"
                  sx={{
                    ml: 1,
                    color: '#333',
                    fontWeight: 600
                  }}
                >
                  Intratalent
                </Typography>
              </Box>

              {/* Desktop Navigation */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                {navItems.map((item, index) => {
                  if (item.href && location.pathname !== '/') {
                    return null;
                  }
                  
                  if (item.href) {
                    return (
                      <Button
                        key={index}
                        sx={{
                          color: '#333',
                          textTransform: 'none',
                          fontSize: '16px',
                          fontWeight: 500,
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#1a47b8',
                          },
                        }}
                        onClick={() => {
                          const element = document.querySelector(item.href);
                          if (element) {
                            const offset = 80;
                            const elementPosition = element.getBoundingClientRect().top;
                            const offsetPosition = elementPosition + window.pageYOffset - offset;
                            window.scrollTo({
                              top: offsetPosition,
                              behavior: 'smooth'
                            });
                          }
                        }}
                      >
                        {item.label}
                      </Button>
                    );
                  }
                  return (
                    <Button
                      key={index}
                      component={Link}
                      to={item.path}
                      sx={{
                        color: '#333',
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 500,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: '#1a47b8',
                        },
                      }}
                    >
                      {item.label}
                    </Button>
                  );
                })}

                {/* Desktop Login/Logout */}
                {token ? (
                  <Button
                    onClick={handleLogout}
                    sx={{
                      color: '#333',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 500,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#1a47b8',
                      },
                    }}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    component={Link}
                    to="/login"
                    sx={{
                      backgroundColor: '#1a47b8',
                      color: '#fff',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 500,
                      padding: '8px 24px',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#153a94',
                      },
                    }}
                  >
                    Log in
                  </Button>
                )}
              </Box>

              {/* Mobile Menu Icon */}
              <IconButton
                sx={{ 
                  display: { xs: 'flex', md: 'none' },
                  color: '#333'
                }}
                onClick={handleMobileMenuOpen}
              >
                <MenuIcon />
              </IconButton>

              {/* Mobile Menu */}
              <Menu
                anchorEl={mobileMenuAnchor}
                open={Boolean(mobileMenuAnchor)}
                onClose={handleMobileMenuClose}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiPaper-root': {
                    width: '200px',
                    marginTop: '8px',
                  },
                }}
              >
                {navItems.map((item, index) => {
                  if (item.href && location.pathname !== '/') {
                    return null;
                  }

                  if (item.href) {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          const element = document.querySelector(item.href);
                          if (element) {
                            const offset = 80;
                            const elementPosition = element.getBoundingClientRect().top;
                            const offsetPosition = elementPosition + window.pageYOffset - offset;
                            window.scrollTo({
                              top: offsetPosition,
                              behavior: 'smooth'
                            });
                          }
                          handleMobileMenuClose();
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem
                      key={index}
                      component={Link}
                      to={item.path}
                      onClick={handleMobileMenuClose}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
                
                {/* Mobile Login/Logout */}
                {token ? (
                  <MenuItem onClick={handleLogout}>
                    Logout
                  </MenuItem>
                ) : (
                  <MenuItem
                    component={Link}
                    to="/login"
                    onClick={handleMobileMenuClose}
                  >
                    Log in
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <ContentOffset />
    </>
  );
};

const SectionWrapper = styled('div')({
  scrollMarginTop: '80px', // This ensures the section starts below the navbar
  padding: '40px 0', // Add vertical padding to sections
});

function AppContent() {
  return (
    <BrowserRouter>
      <Box sx={{ minHeight: '100vh' }}>
        <Navigation />
        <Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/signup" element={<AuthPage />} />
            <Route path="/verify-email/:token" element={<VerificationPage />} />
            <Route path="/demo" element={<ResultsDisplay />} />

            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search/:listingId"
              element={
                <ProtectedRoute>
                  <ResultsDisplay />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload/:listingId"
              element={
                <ProtectedRoute>
                  <ResumeUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search/all"
              element={
                <ProtectedRoute>
                  <ResultsDisplay />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;